import {Component, Input} from '@angular/core';
import {TenantConfigService} from "../../../tenant/services/tenant-config.service";
import {Event, NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent{

  @Input() containerClass = 'container';
  isCollapsed = true;

  constructor(private router: Router, public tenantConfigService: TenantConfigService) {
    router.events.subscribe((event: Event) => {
      // see also
      if(event instanceof NavigationEnd) {
        this.isCollapsed = true;
      }
    });
  }

}
