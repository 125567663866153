import {Component, Input} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";

@Component({
  selector: 'app-authorization-required-modal',
  templateUrl: './authorization-required-modal.component.html',
  styleUrls: ['./authorization-required-modal.component.scss']
})
export class AuthorizationRequiredModalComponent {

  constructor(public activeModal: NgbActiveModal, private router: Router) {}

  login() {
    this.router.navigate(['/account/sign-in']);
    this.activeModal.close();
  }

  signUp() {
    this.router.navigate(['/account/sign-up/individual']);
    this.activeModal.close();
  }

}
