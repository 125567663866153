import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable, BehaviorSubject} from "rxjs";
import {PaginatableInterface} from "../../../shared/interfaces/PaginatableInterface";
import {Notification} from "../../../shared/models/Notification";
import {EnvironmentService} from "../../../shared/services/environment.service";

@Injectable({
  providedIn: "root"
})
export class NotificationService {

  protected dataStore: {
    unseenNotificationCount: number,
    notifications: Notification[],
  };

  unseenNotificationCount: Observable<number>;
  protected _unseenNotificationCount: BehaviorSubject<number>;

  notifications: Observable<Notification[]>;
  protected _notifications: BehaviorSubject<Notification[]>;

  public paginate = {
    total: 0,
    perPage: 0,
    page: 0,
  };

  public loading = {
    notifications: false,
  };

  constructor(private http: HttpClient, private environment: EnvironmentService) {
    this.dataStore = {
      unseenNotificationCount: 0,
      notifications: []
    };
    this._unseenNotificationCount = new BehaviorSubject<number>(0);
    this.unseenNotificationCount = this._unseenNotificationCount.asObservable();

    this._notifications = new BehaviorSubject<Notification[]>([]);
    this.notifications = this._notifications.asObservable();
  }

  refreshUnseenNotificationCounter() {
    this.loading.notifications = true;

    this.http.get(this.environment.apiBaseUrl + 'notification/unread_count').subscribe((data: any) => {
      this.dataStore.unseenNotificationCount = data;
      this._unseenNotificationCount.next(Object.assign({}, this.dataStore).unseenNotificationCount);
    });
  }

  refreshNotifications(params: HttpParams): void {
    this.http.get<PaginatableInterface<Notification[]>>(this.environment.apiBaseUrl + 'notification', {params: params}).subscribe((result: PaginatableInterface<Notification[]>) => {
      this.dataStore.notifications = [];
      this.paginate.perPage = result.per_page;
      this.paginate.total = result.total;
      this.paginate.page = result.current_page;

      result.data.forEach(item => {
        this.dataStore.notifications.push(Notification.create(item));
      });

      this.loading.notifications = false;

      this._notifications.next(Object.assign({}, this.dataStore).notifications);
    });
  }

  remove(id: string) {
    this.http.delete(this.environment.apiBaseUrl + 'notification/' + id).subscribe(result => {
      this.dataStore.notifications.forEach((t, i) => {
        if (t.id !== id) {
          return;
        }

        this.dataStore.notifications.splice(i, 1);
      });

      this._notifications.next(Object.assign({}, this.dataStore).notifications);
    });
  }

  readNotification(notification: Notification) {
    this.http.get(this.environment.apiBaseUrl + 'notification/' + notification.id).subscribe(result => {
      if(!notification.is_read) {
        this.dataStore.unseenNotificationCount = this.dataStore.unseenNotificationCount - 1;
        this._unseenNotificationCount.next(Object.assign({}, this.dataStore).unseenNotificationCount);
      }
      notification.is_read = true;
    });
  }

}
