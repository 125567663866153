import {JsonAdapter} from "../helpers/JsonAdapter";
import {User} from "./User";
import {Message} from "./Message";
import {Portfolio} from "./Portfolio";
import {Organisation} from "./Organisation";

export class Notification {

  private static RECEIVED_EOI: string = "received_eoi";
  private static RECEIVED_EOI_RESPONSE: string = "received_eoi_response";
  private static RECEIVED_MESSAGE: string = "received_message";
  private static PORTFOLIO_REQUEST_CHANGES: string = "portfolio_request_changes";
  private static PORTFOLIO_WAITING_FOR_APPROVAL: string = "portfolio_waiting_for_approval";
  private static PORTFOLIO_APPROVED: string = "portfolio_approved";

  id: string = "";
  event_type: string = "";
  created_at: string = "";
  is_read: boolean = false;
  source_user?: User;
  source_user_organisation?: Organisation;
  user?: User;
  message?: Message;
  portfolio?: {id: string};
  expression_of_interest?: {id: string};


  static create(object: Notification) {
    const item = JsonAdapter.fill(new Notification(), object);

    if(object.source_user_organisation) {
      item.source_user_organisation = Organisation.create(object.source_user_organisation);
    }

    if(object.source_user) {
      item.source_user = User.create(object.source_user);
    }

    if(object.message) {
      item.message = Message.create(object.message);
    }

    if(object.portfolio) {
      item.portfolio = object.portfolio;
    }

    if(object.expression_of_interest) {
      item.expression_of_interest = object.expression_of_interest;
    }

    return item;
  }

  static createMultiple(objects: Notification[]) {
    const items = [];
    for (let i = 0; i < objects.length; i++) {
      items.push(Notification.create(objects[i]));
    }
    return items;
  }

  get title() {
    switch(this.event_type) {
      case Notification.RECEIVED_EOI:
        return "New Interest Alert";
      case Notification.RECEIVED_EOI_RESPONSE:
        return "Candidate Responded";
      case Notification.RECEIVED_MESSAGE:
        return "New message";
      case Notification.PORTFOLIO_REQUEST_CHANGES:
      case Notification.PORTFOLIO_WAITING_FOR_APPROVAL:
      case Notification.PORTFOLIO_APPROVED:
        return "Portfolio";
    }
    return "";
  }

  get icon() {
    switch(this.event_type) {
      case Notification.RECEIVED_EOI:
      case Notification.RECEIVED_EOI_RESPONSE:
        return "faBells";
      case Notification.RECEIVED_MESSAGE:
        return "faEnvelope";
      case Notification.PORTFOLIO_REQUEST_CHANGES:
      case Notification.PORTFOLIO_WAITING_FOR_APPROVAL:
      case Notification.PORTFOLIO_APPROVED:
        return "faIdBadge";
    }
    return "faEnvelope";
  }

  get body() {
    switch(this.event_type) {
      case Notification.RECEIVED_EOI:
        return this.source_user?.fullname + " from " + this.source_user_organisation?.name + " would like to contact you.";
      case Notification.RECEIVED_EOI_RESPONSE:
        return "Candidate responded to your Interest Alert.";
      case Notification.RECEIVED_MESSAGE:
        return this.source_user?.fullname + " sent you a message";
      case Notification.PORTFOLIO_REQUEST_CHANGES:
        return this.source_user?.fullname + " requested more changes on your Portfolio";
      case Notification.PORTFOLIO_WAITING_FOR_APPROVAL:
        return this.source_user?.fullname + " requested a Portfolio Approval";
      case Notification.PORTFOLIO_APPROVED:
        return this.source_user?.fullname + " approved your Portfolio";
    }

    return "";
  }

  get url() {
    switch(this.event_type) {
      case Notification.RECEIVED_EOI:
      case Notification.RECEIVED_EOI_RESPONSE:
        return '/dashboard/interest-alerts/' + this.expression_of_interest?.id;
      case Notification.RECEIVED_MESSAGE:
        return '/dashboard/messages/' + this.message?.conversation?.id;
      case Notification.PORTFOLIO_REQUEST_CHANGES:
      case Notification.PORTFOLIO_APPROVED:
        return '/dashboard/profile/portfolio';
      case Notification.PORTFOLIO_WAITING_FOR_APPROVAL:
        return '/dashboard/approvals/' + this.portfolio?.id;
    }

    return "/dashboard";
  }
}
