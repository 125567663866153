<footer>
  <div class="footer-main">

    <div class="container">

      <div class="row">
        <div class="col-md-5">
          <div class="footer-title">
            About Us
          </div>
          <div class="footer-about-us">
            The Destination Nuclear Careers Portal is your one-stop shop for finding a role in the nuclear sector. We have a comprehensive database of jobs from all across the sector.
          </div>
          <div class="footer-partner-with">
            Partnering with Destination Nuclear
            <a href="https://destinationnuclear.com" target="_blank">
              <img [src]="'assets/tenants/destination-nuclear/destination_nuclear_logo.svg'" STYLE="width: 157px; height: 40px; display: block; margin-top: 15px;">
            </a>
          </div>
        </div>
        <div class="col-md-3 offset-md-1">
          <div class="footer-title">
            Helpful Links
          </div>
          <ul class="footer-nav">
            <li><a [routerLink]="['/contact-us' | routeTenant]">Contact us</a></li>
            <li><a href="/static/docs/Terms_and_Conditions_for_Destination_Nuclear.pdf" title="PDF" target="_blank">Terms & Conditions</a></li>
            <li><a href="/static/docs/Winchester%20Consulting%20-%20Privacy%20Notice%20for%20Destination%20Nuclear.pdf" title="PDF" target="_blank">Privacy & Data Policy</a></li>
            <li><a href="/static/docs/Disclaimer.pdf" title="PDF" target="_blank">Policy and Disclaimer</a></li>
            <li><a href="/static/docs/Cookie_Notice.pdf" title="PDF" target="_blank">Cookie Notice</a></li>
            <li><a href="/static/docs/Destination%20Nuclear%20Careers%20Portal%20Accessibility%20Statement.pdf" title="PDF" target="_blank">Accessibility Statement</a></li>
            <li><a href="/static/docs/Destination%20Nuclear%20Careers%20Portal%20EDI%20statement.pdf" title="PDF" target="_blank">EDI Statement</a></li>
          </ul>
        </div>
        <div class="col-md-3">
          <div class="footer-title">
            Stay in Touch
          </div>
          <div class="info-box contact-us-box">
            <a href="https://www.linkedin.com/company/destination-nuclear/" target="_blank">
              <fa-icon [icon]="'faLinkedin' | icon" [size]="'2x'" class="me-2"></fa-icon> LINKEDIN
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
