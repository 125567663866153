<nav class="navbar top-navbar">
  <div [class]="containerClass">
    <a [routerLink]="'/' | routeTenant" class="navbar-brand" [ngStyle]="{'background-image': 'url(' + tenantConfigService.tenant.logo + ')'}"></a>
    <div class="navbar-collapse-overlay d-md-none" [ngClass]="{'is-open': !isCollapsed}" role="button" tabindex="0" (click)="isCollapsed = !isCollapsed"></div>
    <app-top-navigation class="top-navigation" [ngClass]="{'is-open': !isCollapsed}"></app-top-navigation>
    <!-- Toggler -->
    <button class="navbar-toggler d-md-none" type="button" (click)="isCollapsed = !isCollapsed">
      <span class="navbar-toggler-icon"></span>
    </button>
  </div>
</nav>
