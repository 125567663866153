<ul class="navigation">
  <ng-container *ngIf="authService.isLoggedIn()">
    <li class="navigation-item d-flex d-lg-none">
      <span role="button" class="avatar avatar-md position-relative">
          <img [src]="(authService.currentUser$ | async)?.avatar | secureImage | async | safe:'url'" class="avatar-img rounded-circle" alt="...">
      </span>
      <div class="h2 ps-2"><div class="d-block mb-1">Welcome back,</div> <b>{{ (authService.currentUser$ | async)?.first_name }}</b></div>
    </li>
  </ng-container>
  <ng-container *ngFor="let navItem of navigationService.navigationItems | async">
    <li class="navigation-item" *ngIf="!navItem.hiddenExternally && ((!navItem.visibleIfGuest && !navItem.visibleIfMember) || (navItem.visibleIfGuest && authService.isLoggedOut()) || (navItem.visibleIfMember && authService.isLoggedIn()))" [routerLinkActive]="'active'">
      <a role="button" class="navigation-link {{navItem.class || ''}}" *ngIf="navItem.callbackFn" (click)="callback(navItem.callbackFn)" [ngbTooltip]="navItem.tooltip">
        <ng-container *ngIf="!navItem.icon">{{ navItem.title }}</ng-container>
        <ng-container *ngIf="navItem.icon"><fa-icon [icon]="navItem.icon | icon"></fa-icon></ng-container>
      </a>
      <a [routerLink]="[navItem.routerLink | routeTenant]" class="navigation-link {{navItem.class || ''}}" *ngIf="!navItem.callbackFn" [ngbTooltip]="navItem.tooltip">
        <ng-container *ngIf="!navItem.icon">{{ navItem.title }}</ng-container>
        <ng-container *ngIf="navItem.icon"><fa-icon [icon]="navItem.icon | icon"></fa-icon></ng-container>
      </a>
    </li>
  </ng-container>
  <li class="navigation-item d-none d-lg-inline-block" *ngIf="authService.isLoggedIn()">
    <div ngbDropdown display="dynamic" placement="bottom-end">
      <span role="button" class="avatar avatar-sm position-relative" ngbDropdownToggle>
        <img [src]="(authService.currentUser$ | async)?.avatar | secureImage | async | safe:'url'" class="avatar-img rounded-circle" alt="...">
        <span class="position-absolute translate-middle badge rounded-pill bg-danger ms-2" *ngIf="!tenantConfigService.tenant.topNavigation.hideNotification && (notificationService.unseenNotificationCount | async) as count">
          {{ count }}
          <span class="visually-hidden">unread messages</span>
        </span>
      </span>
      <div ngbDropdownMenu class="account-info-dropdown">
        <div class="h2 ps-2"><div class="d-block mb-1">Welcome back,</div> <b>{{ (authService.currentUser$ | async)?.first_name }}</b></div>
        <a [routerLink]="['/dashboard/account' | routeTenant]" [routerLinkActive]="'active'" ngbDropdownItem class="border-0">
          <fa-icon [icon]="'faUser' | icon"></fa-icon> Account
        </a>
        <a ngbDropdownItem role="button" class="" [routerLinkActive]="'active'" *ngIf="authService.hasRole('organisation_manager')" [routerLink]="['/dashboard/organisation-profile/' + (authService.currentUser$ | async)?.organisation?.slug | routeTenant]">
          <fa-icon [icon]="'faBuildingColumns' | icon"></fa-icon> Organisation Management
        </a>
        <!--a ngbDropdownItem role="button" class="" [routerLinkActive]="'active'" [routerLink]="['/dashboard/account/notifications' | routeTenant]"  *ngIf="tenantConfigService.tenant.topNavigation.hideNotification">
          <fa-icon [icon]="'faBell' | icon"></fa-icon> Notification Settings
        </a-->
        <a ngbDropdownItem role="button" class="position-relative" *ngIf="!tenantConfigService.tenant.topNavigation.hideNotification">
          <fa-icon [icon]="'faBell' | icon"></fa-icon>
          Notifications
          <span class="position-absolute badge rounded-pill bg-danger ms-2" *ngIf="(notificationService.unseenNotificationCount | async) as count">
                {{ count }}
            <span class="visually-hidden">unread messages</span>
              </span>
        </a>
        <a ngbDropdownItem role="button" class="position-relative"  (click)="logout()">
          <fa-icon [icon]="'faArrowRightFromBracket' | icon"></fa-icon>
          Sign Out
        </a>
      </div>
    </div>
  </li>
  <ng-container *ngIf="authService.isLoggedIn()">
    <li class="navigation-item d-lg-none">
      <a role="button" class="navigation-link"  (click)="logout()">
        <fa-icon [icon]="'faArrowRightFromBracket' | icon"></fa-icon>
        Sign Out
      </a>
    </li>
  </ng-container>
</ul>
