import {Component, OnDestroy} from '@angular/core';
import {Event, NavigationEnd, NavigationError, NavigationStart, Router} from "@angular/router";
import {filter, Subscription} from "rxjs";
import { Location } from '@angular/common';
import {TenantConfigService} from "../../../tenant/services/tenant-config.service";

@Component({
  selector: 'app-gateway',
  templateUrl: './gateway.component.html',
  styleUrls: ['./gateway.component.scss']
})
export class GatewayComponent implements OnDestroy {

  hideHeader = false;
  hideFooter = false;

  subscriptions = new Subscription();

  constructor(private router: Router, private location: Location, private tenantConfigService: TenantConfigService) {

    this.checkAuthPath();
    this.subscriptions.add(router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((val) => {
      this.checkAuthPath();
    }));
  }

  private checkAuthPath() {
    this.hideFooter = this.hideHeader = this.tenantConfigService.tenant.signIn.fullscreen && this.location.path().indexOf('account') > -1;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
