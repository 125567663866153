import { Component } from '@angular/core';
import {AuthService} from "../../../auth/services/auth.service";
import {NavigationService} from "../../../services/navigation.service";
import {NotificationService} from "../../../../internal/notification/services/notification.service";
import {TenantConfigService} from "../../../../tenant/services/tenant-config.service";

@Component({
  selector: 'app-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss']
})
export class TopNavigationComponent {
  constructor(public authService: AuthService, public navigationService: NavigationService, public notificationService: NotificationService,
              public tenantConfigService: TenantConfigService) {
  }

  callback(callbackFn: any) {
    // @ts-ignore
    this[callbackFn]();
  }

  logout() {
    this.authService.logout();
  }
}
