import { Injectable } from '@angular/core';
import {NavigationItem} from "../models/NavigationItem";
import {BehaviorSubject} from "rxjs";
import {TenantConfig} from "../../tenant/models/TenantConfig";
import {TenantService} from "../../tenant/services/tenant.service";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  navigationItems: BehaviorSubject<NavigationItem[]> = new BehaviorSubject<NavigationItem[]>([]);
  tenantConfig: TenantConfig | null;

  constructor(private tenantService: TenantService) {

    this.tenantConfig = this.tenantService.tenantConfig;
    if(this.tenantConfig && this.tenantConfig.topNavigation) {
      this.navigationItems.next(this.tenantConfig.topNavigation.items);
    }
  }
}
