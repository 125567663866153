import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-tenant-gateway',
  templateUrl: './tenant-gateway.component.html',
  styleUrls: ['./tenant-gateway.component.scss']
})
export class TenantGatewayComponent {

  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(param => {

    });
  }
}
