<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Authorization required</h4>
  <button type="button" class="btn-close" aria-describedby="modal-title" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <p>To use this functionality you need to be logged in. Please click on the login or the sign up button.</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="activeModal.dismiss('cancel click')">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="login()">Log in</button>
  <button type="button" class="btn btn-primary" (click)="signUp()">Sign Up</button>
</div>
